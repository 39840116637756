<template>
	<div class="marketplace">
		<top-nav></top-nav>

		<div class="home">
			<div class="part0">
				<div class="part1BookDemo">
					<!-- <div class="part11"> -->
					<div class="part1Total">
						<div class="part1Middle">
							<div class="pic1">
								<img src="./../../assets/imgs/pc/marketplace/background1.png" style="height:932px">
							</div>
							<div class="part1Title">
								Project Marketplace
							</div>
							<div class="part1Content">
								Off the plan sales opportunities await with Arcanite's exciting networking marketplace.
								Whether you're looking to incorporate project sales, or already sell properties off the
								plan, Project Marketplace has new and upcoming developments waiting for agents to sell.
							</div>
							<a v-if="!isID" class="button_click" onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
      							return false;">
								<button class="part1Button">
									<div class="bottomText">Book A Demo</div><img
										src="./../../assets/imgs/pc/homepage/p12Vector.png" class="p12">
								</button>
							</a>
							<a v-else class="button_click" onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');
      							return false;">
								<button class="part1Button">
									<div class="bottomText">Book A Demo</div><img
										src="./../../assets/imgs/pc/homepage/p12Vector.png" class="p12">
								</button>
							</a>
							<img class="pics1" src="./../../assets/imgs/pc/marketplace/pics1.png">
						</div>
					</div>
				</div>
			</div>

			<div class="part2">
				<div class="part2Total">
					<div class="part2Top">
						<div class="part2TopTitle">
							Build your professional network
						</div>
						<div class="part2TopContent">
							Connect with others looking to increase business opportunities through newfound sales
							channels
						</div>
					</div>
					<div class="part2Bottom">
						<div class="part2BottomContent">
							<div class="items">
								<div class="item" @mouseover ="playStart('property')"
									@mouseleave ="playEnd('property')">
									<div class="itemImg">
										<div class="part2_1">
											<lottie :options="defaultOptions.property"
												@animCreated="handleAnimation($event,'property')" :speed="0.5" />
										</div>
										<!-- <img class="part2_1" src="./../../assets/imgs/pc/marketplace/part2_1.png"> -->
									</div>
									<div class="itemTitle">
										More properties
									</div>
									<div class="itemContent">
										Diversify your property offerings
									</div>
								</div>
								<div class="item" @mouseover="playStart('developer')"
									@mouseleave ="playEnd('developer')">
									<div class="itemImg">
										<div class="part2_2">
											<lottie :options="defaultOptions.developer"
												@animCreated="handleAnimation($event, 'developer')" :speed="0.5" />
										</div>
										<!-- <img class="part2_2" src="./../../assets/imgs/pc/marketplace/part2_2.png"> -->
									</div>
									<div class="itemTitle">
										More developers
									</div>
									<div class="itemContent">
										Network with property developers
									</div>
								</div>
								<div class="item" @mouseover ="playStart('opportunity')"
									@mouseleave ="playEnd('opportunity')">
									<div class="itemImg">
										<div class="part2_3">
											<lottie :options="defaultOptions.opportunity"
												@animCreated="handleAnimation($event, 'opportunity')" :speed="0.5" />
										</div>
										<!-- <img class="part2_3" src="./../../assets/imgs/pc/marketplace/part2_3.png"> -->
									</div>
									<div class="itemTitle">
										More opportunities
									</div>
									<div class="itemContent">
										Gain access to projects to sell
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="part3">
				<div class="part3Total">
					<div class="part3_1" @mouseover.once ="playStart('project')">
						<div class="part3_1Left">
							<div class="part3_1Pic">
								<lottie :options="defaultOptions.project"
									@animCreated="handleAnimation($event, 'project')" :speed="1" />
							</div>
							<!-- <img src="../../assets/imgs/pc/marketplace/image1.png" style="width: 615px;height: 424px;"> -->
						</div>
						<div class="part3_1Right rightContent">
							<div class="p1">
								<img src="./../../assets/imgs/pc/marketplace/icon1.png" class="pic" />
								<p>Pick your projects</p>
								<div class="content">
									Connect with property developers who </br> have upcoming projects in your area
								</div>
								<div class="line"></div>
								<div class="content">
									<div class="fullyLine">
										<div class="dog"></div>
										<div class="text">Choose who you connect with</div><br>
									</div>
									<div class="dog"></div>
									<div class="text">Discover upcoming off the plan projects near you</div><br>
									<div class="dog"></div>
									<div class="text">Select projects that suit your buyers</div><br>
								</div>
							</div>
						</div>
					</div>
					<div class="part3_2" @mouseover.once ="playStart('way')">
						<div class="part3_2Left">

							
							<img class="circleBack" src="../../assets/imgs/pc/marketplace/circleBackg.png">
							<div class="rightContent">
								<div class="p1">
									<img src="./../../assets/imgs/pc/marketplace/icon2.png" class="pic" />
									<p>Your marketplace your way</p>
									<div class="content">
										Choose how many connections you engage with and which projects interest you
									</div>
									<div class="line"></div>
									<div class="content">
										<div class="fullyLine">
											<div class="dog"></div>
											<div class="text">Make strategic connections</div><br>
										</div>
										<div class="dog"></div>
										<div class="text">Utilise marketplace as often as you choose</div><br>
										<div class="dog"></div>
										<div class="text">Designed for Arcanite members and run by Arcanite members
										</div><br>
									</div>
								</div>
							</div>
						</div>
						<div class="part3_2Right">
							<div class="part3_2Pic">
								<lottie :options="defaultOptions.way"
									@animCreated="handleAnimation($event, 'way')" :speed="1" />
							</div>
							<!-- <img src="../../assets/imgs/pc/marketplace/image2.png" style="width: 583px;height: 501px;"> -->
						</div>
					</div>
					<div class="part3_3" @mouseover.once ="playStart('revenue')">
						<div class="part3_3Left">
							<div class="part3_3Pic">
								<lottie :options="defaultOptions.revenue"
									@animCreated="handleAnimation($event, 'revenue')" :speed="1" />
							</div>
							<!-- <img src="../../assets/imgs/pc/marketplace/image3.png" style="width: 552px;height: 500px;"> -->
						</div>
						<div class="part3_3Right rightContent">
							<div class="p1">
								<img src="./../../assets/imgs/pc/marketplace/icon3.png" class="pic" />
								<p>Increase your sales revenue</p>
								<div class="content">
									With a marketplace full of properties to sell </br>the opportunities are endless
								</div>
								<div class="line"></div>
								<div class="content">
									<div class="fullyLine">
										<div class="dog"></div>
										<div class="text">Always have projects available to sell</div><br>
									</div>
									<div class="dog"></div>
									<div class="text">No time spent outsourcing stock</div><br>
									<div class="dog"></div>
									<div class="text">Sell effortlessly through your Arcanite app</div><br>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="part4Comments">
				<other-product></other-product>
			</div>
		</div>
		<foot-nav></foot-nav>
	</div>

</template>

<script>
	export default {
		components: {
			"top-nav": resolve => require(["./TopNav.vue"], resolve),
			"foot-nav": resolve => require(["./FooterNav.vue"], resolve),
			"other-product": (resolve) => require(["./OtherProduct.vue"], resolve),
			// "propertyAnmiationData":(resolve) => require(['@/assets/imgs/pc/marketplace/Moreproperties.json'], resolve),

		},
		data() {
			return {
				isID:false,
				defaultOptions: {
					property: {
						animationData: require('@/assets/imgs/pc/marketplace/moreProperties.json'),
						autoplay: true,
						loop: false,
						playSpeed: 0.5,
					},

					developer: {
						animationData: require('@/assets/imgs/pc/marketplace/moreDevelopers.json'),
						autoplay: true,
						loop: false,
						playSpeed: 0.5,
					},
					
					opportunity: {
						animationData: require('@/assets/imgs/pc/marketplace/moreOpportunities.json'),
						autoplay: true,
						loop: false,
						playSpeed: 0.5,
					},
					project: {
						animationData: require('@/assets/imgs/pc/marketplace/pickYourProjects.json'),
						autoplay: false,
						loop: true,
						playSpeed: 1,
					},
					way: {
						animationData: require('@/assets/imgs/pc/marketplace/YourMarketplaceYourWay.json'),
						autoplay: false,
						loop: true,
						playSpeed: 1,
					},
					
					revenue: {
						animationData: require('@/assets/imgs/pc/marketplace/IncreaseYourSalesRevenue.json'),
						autoplay: false,
						loop: true,
						playSpeed: 1,
					},

				},
				animationPlay: true,
				anim:{},
			}
		},
		created(){
			if(localStorage.getItem('country')=="id"){
				this.isID=true;
			}else{
				this.isID=false;
			}
		},
		methods: {
			handleAnimation(event,animationName) {
				this.anim[animationName] = event;
			},
			playStart(animationName) {
				// this.anim.setSpeed(0.5);
				this.anim[animationName].playSegments([0,this.anim[animationName].totalFrames],true);
			},
			playEnd(animationName) {
				// this.anim[animationName].goToAndStop(this.anim[animationName].totalFrames-1,true);
			},
		},
		computed: {
			
		},
	}
</script>

<style lang="scss" scoped>
	// .marketplace {
	// 	width: 100vw;
	// 	height:100%;
	// 	display: inline-block;
	// 	text-align: center;
	// 	overflow-x:hidden;
	// 	font-family: Poppins;
	// 	min-width: 1210px;
	// }
	.home{
		width: 100vw;
		// width: 1210px;
		display: inline-block;
		text-align: center;
		position: relative;
		font-family: Poppins;
		overflow-x:hidden;
		z-index: 6;
	}

	.part0 {
		width: 100vw;
		// width: 1210px;
		height: 1050px;
		position: relative;
		margin-bottom: 200px;
		// top:90px;
	}

	.part1BookDemo {
		margin: auto;
		width: 100vw;
		// width:2560px;
		// height:932px;
		z-index: 1;
		position: relative;

		.part1Total {
			z-index: -5;
			height: 1200px;
			margin: auto;
			position: relative;
			overflow: hidden;

			.pic1 {
				z-index: -99;
				position: absolute;
				left: -670px;
				right: -670px;
				top: 90px;
			}

			.part1Middle {
				width: 1280px;
				margin: 0 auto;

				.p12 {
					width: 22px;
					height: 22px;
					flex-grow: 0;
					// background-color: #fff;
				}

				.part1Title {
					z-index: 10;
					position: relative;
					width: 581px;
					height: 62px;
					// left: 153px;
					margin: 0 auto;
					top: 153px;
					color: #FFFFFF;
					font-family: 'Poppins-Bold';
					font-style: normal;
					font-size: 56px;
					line-height: 62px;
				}

				.part1Content {
					z-index: 11;
					postion: relative;
					top: 245px;
					width: 1045px;
					height: 90px;
					font-family: 'Poppins';
					font-style: normal;
					font-weight: 400;
					font-size: 20px;
					line-height: 30px;
					color: #F2F2F2;
					margin: 0 auto;
					margin-top: 183px;
				}

				.part1Button {
					z-index: 99;
					position: relative;
					right: 10px;
					margin: 0 auto;
					margin-top: 40px;
					width: 172px;
					height: 42px;
					flex-grow: 0;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					gap: 10px;
					padding: 10px 16px;
					border-radius: 5px;
					background-color: #062440;
					cursor: pointer;
				}

				.bottomText {
					white-space: nowrap;
					font-family: Poppins-Bold;
					font-size: 16px;
					// font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.38;
					letter-spacing: normal;
					text-align: center;
					color: #fff;
					width: 111px;
					height: 22px;
					flex-grow: 0;
				}

				.pics1 {
					width: 1073px;
					height: 778px;
					margin-top: 15px;
					position: relative;
					right: 30px;
				}
			}
		}
	}

	.part2 {
		width: 100vm;
		position: relative;
		height: 593px;
		// margin-top: 250px;
		.part2Total {
			margin-bottom: 122px;
		}
		.part2TopTitle {
			margin: 0 auto;
			width: 586px;
			height: 42px;
			font-family: 'Poppins-Bold';
			font-style: normal;
			font-size: 36px;
			line-height: 42px;
			text-align: center;
			color: #062440;
		}

		.part2TopContent {
			margin: 0 auto;
			margin-top: 30px;
			width: 654px;
			height: 60px;
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 30px;
			text-align: center;
			color: #314455;
		}

		.part2Bottom {
			margin: 0 auto;
			margin-top: 67px;

			.items {

				display: flex;
				flex-direction: row;
				justify-content: center;
				flex-wrap: nowrap;
				flex-grow: 0;


				.item {
					// display: inline-block;
					position: relative;
					width: 271px;
					height: 274px;
					margin: 0 19px;
					flex-shrink: 0;
					border: 1px solid #1890FF;
					box-sizing: border-box;
					border: 1px solid #E6EBF2;
					box-sizing: border-box;
					border-radius: 10px;

					&:hover {
						border: 1px solid #1890FF;
						box-sizing: border-box;
						box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
						border-radius: 10px;
					}

					.part2_1 {
						position: absolute;
						// width: 220px;
						width: 150%;
						height: 150%;
						bottom: -45px;
						left: -70px;
						z-index: -1;

						// height:100%;
						// height: 67px;
						// margin: 84px 25px 0 25px;
					}

					.part2_2 {
						position: absolute;
						width: 130% !important;
						height:130% !important;
						left: -70px;
						bottom: 20px;
						width: 170px;
						height: 139px;
						margin: 12px 50px 0 50px;
						z-index: -1;
						// position: relative;
						// bottom: -123px;
					}

					.part2_3 {
						position: absolute;
						bottom: 110px;
						width: 221px;
						height: 125px;
						margin: 26px 25px 0 25px;
						z-index: -1;
					}

					.itemTitle {
						position: absolute;
						bottom: 71px;
						left: 42px;
						width: 186px;
						height: 22px;
						margin: 0 auto;
						margin-top: 30px;
						font-family: 'Poppins-Bold';
						font-style: normal;
						font-size: 16px;
						line-height: 22px;
						letter-spacing: 0.012em;
						color: #314455;

					}

					.itemContent {
						position: absolute;
						bottom: 21px;
						left: 46px;


						margin: 0 auto;
						margin-top: 10px;
						font-family: 'Poppins';
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 20px;
						color: #67788C;
						width: 186px;
						height: 40px;
					}
				}
			}
		}
	}

	.part3 {
		width: 100vw;
		height: 1779px;
		// margin-top: 152px;
		position: relative;

		.part3Total {

			.part3_1 {
				background: #F8F8F8;
				display: flex;
				flex-direction: row;
				justify-content: center;
				z-index:20;
				gap:22px;

				.part3_1Left {
					z-index:9;
					margin-top: 86px;
					// margin-right: 22px;
					position:relative;
					height: 593px;
					width: 695px;
					// left: 26px;
					// top: 8px;
					border-radius: 0px;

					.part3_1Pic{
						z-index:7;
						position: absolute;
						width: 695px;
					}
				}
			}

			.part3_2 {
				display: flex;
				flex-direction: row;
				// position: relative;
				margin-left: 50px;
				justify-content: center;

				.part3_2Left {
					position: relative;

					.rightContent {
						padding-right: 100px;
					}

					.circleBack {
						position: absolute;
						width: 528px;
						height: 528px;
						right: 0;
						top: 58px;
						z-index: -99;
					}
				}

				.part3_2Right {
					// margin-left:52px;
					margin-top: 71px;
					height: 593px;
					width: 775px;
					position: relative;
					.part3_2Pic{
						position: absolute;
						width: 505px;
						right: 150px;
						bottom: 50px;
						
					}
				}
			}

			.part3_3 {
				display: flex;
				flex-direction: row;
				justify-content: center;
				gap: 48px;
				.part3_3Left {
					margin-top: 50px;
					// margin-right: 48px;
					height: 593px;
					width: 635px;
					position: relative;
					.part3_3Pic{
						position: absolute;
						width: 635px;
						right: 0px;
						bottom: 0px;
					}
				}

			}

		}


		.rightContent {
			position: relative;
			display: inline-block;
			width: 454px;
			// margin-left: 25px;
			height: 597px;
			z-index: 7;
		}

		.p1 {
			width: 500px;
			margin: 10px;
			position: absolute;
			top: 20%;
			text-align: left;

			.pic {
				width: 80px;
				height: 80px;
			}

			p {
				margin-left: 10px;
				position: relative;
				font-family: Poppins-Bold;
				font-size: 36px;
				// font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.17;
				letter-spacing: normal;
				text-align: left;
				color: #062440;
			}

			.content {
				margin-left: 10px;
				width: 454px;
				position: relative;
				flex-grow: 0;
				font-family: Poppins;
				font-size: 20px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.5;
				letter-spacing: normal;
				text-align: left;
				color: #314455;
				margin-top: 10px;
			}

			.line {
				margin: 20px 0 20px 10px;
				width: 463px;
				height: 1px;
				background-color: #e6ebf2;
			}

			.dog {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: #1890ff;
				display: inline-block;
			}

			.text {
				margin: 10px 10px 10px 10px;
				display: inline-block;
				flex-grow: 0;
				font-family: Poppins;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.43;
				letter-spacing: normal;
				text-align: left;
				color: #314455;
				// word-break: break-all;
			}

			.content {
				width: 490px;
				text-align: left;
			}
		}
	}

	.part4Comments {
		display: flex;
		width: 100vw;
		height: 883px;
		text-align: center;
		align-items: center;
		justify-content: center;
	}
</style>
